"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.listFAQ = exports.listNotification = void 0;
exports.listNotification = [
    {
        id: '1',
        name: 'セラピスト名',
        content: 'ここにはプロフィールテキストが入ります。ここにはプロフィールテキストが入ります。ここにはプロフィールテキストが入ります。ここにはプロフィールテキストが入ります。',
        pubished_at: '10月24日',
    },
    {
        id: '2',
        name: 'セラピスト名',
        content: 'ここにはプロフィールテキストが入ります。ここにはプロフィールテキストが入ります。ここにはプロフィールテキストが入ります。ここにはプロフィールテキストが入ります。',
        pubished_at: '10月20日',
    },
];
exports.listFAQ = [
    {
        id: '1',
        q: 'セラピーの予約を行いたいのですが？',
        a: 'ログインした上で必要情報を入力のうえご予約ください。',
        expanded: false,
    },
    {
        id: '2',
        q: 'セミナーの予約を行いたいのですが',
        a: 'フォームに必要情報をご入力のうえお申し込みください。',
        expanded: false,
    },
    {
        id: '3',
        q: '登録をするには何が必要ですか？',
        a: 'メールアドレスとSMSを受信できる電話番号をご準備のうえご登録ください。',
        expanded: false,
    },
];
